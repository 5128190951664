@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* ----------------------------------- */

.mainContainer {
  font-family: "SF Pro Display", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  margin: 0;
  padding: 0;
  text-shadow: 1px 1px 1px rgb(0, 0, 0) !important;
}

/* Define the fade-in animation */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
