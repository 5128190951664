/* ============================ */
/* LOADING ANIMATON: */
/* ============================ */

a {
  text-decoration: none !important;
  color: #ebebebcc !important;
}


html {
  scroll-behavior: smooth;
}

/* ============================ */

.alert {
  background-color: red !important;
  color: white !important;
  text-shadow: none !important;
  font-weight: bold;
  margin-top: 100px !important;
}

.FrontPageRow, .aboutMeRow, .contactRow {
  transition: all 0.2s ease-in-out !important;
  max-width: 100%;
  justify-content: center;
  margin: 10vh 18vh calc(11vmax + 100px) calc(4vmax + 100px) !important;
}

.GreenScreenVid {
  /* This is only needed for MacOS for Safari */
  mix-blend-mode: screen !important;
  transform: scaleX(-1);
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 0;
  right: 0; /* Set right to 0 to align it to the far right side */
  bottom: 0;
  margin-top: auto;
  margin-right: 0; /* Set margin-right to 0 to align it to the far right side */
  margin-bottom: auto;
  z-index: -1;
}

.rowHeaderTitle {
  font-size: calc(1.5vmax + 9px); /* Adjust the font size as needed */
  color: #ebebeb;
}

.customHr {
  margin-bottom: 50px !important;
}

.rowHeaderNumber {
  font-size: calc(1vmax + 7px); /* Adjust the font size as needed */
  color: #b0ecff !important;
}

.bioDescColor {
  color: #ebebebcc !important;
}

/* ============================================ */
/* MAIN PAGE */
/* ============================================ */

.title {
  font-size: calc(1vmax + 6px); /* Adjust the font size as needed */
  color: #b0ecff !important;
  margin: 0px !important;
}

.name-title {
  font-size: calc(3vmax + 6px); /* Adjust the font size as needed */
  font-weight: bold;
}

.what-i-do {
  margin-top: -20px !important;
  font-size: calc(2vmax + 6px); /* Adjust the font size as needed */
  font-weight: bold;
  color: #82b1bd !important; 
}

.mini-bio {
  margin: 0px 0px 35px 0px  !important;
  font-size: calc(1vmax + 6px); /* Adjust the font size as needed */
  max-width: 80%;
  }

/* ============================================ */
/* ABOUT ME ROW */
/* ============================================ */

.aboutmeBio {
  font-size: calc(0.9vmax + 8px); /* Adjust the font size as needed */
}

.bioLink {
  text-decoration: none;
  color: #b0ecff;
  transition: all 0.2s ease-in-out;
}

.bioLink:hover {
  color: #9ecdff;
}

.horizontal-list {
  padding: 0; /* remove default padding */
  margin: 0; /* remove default margin */
  font-size: calc(0.8vmax + 5px); /* Adjust the font size as needed */
}

.horizontal-list li {
  display: inline-block; /* display list items horizontally */
  margin-right: 10px; /* add some space between list items */
}

.aboutmePic {
  margin-top: calc(4vmax + 10px) !important;
  position: relative;
  border-radius: 2%;
  max-width: 85%;
  border: 2px solid #568b9c;
  box-shadow: 10px 10px 0 0 #b0ecff;
  filter: grayscale(10%);
  transition: all 0.3s ease-in-out;
}

.aboutmePic:hover {
  filter: grayscale(50%);
  border: 2px solid #3b6b7b;
  box-shadow: 20px 20px 0 0 #b0edffc7;
}

/* ============================================ */
/* PROJECTS */
/* ============================================ */

.witter-row {
  margin-bottom: calc(3vmax + 20px) !important;
  background-color: rgba(53, 53, 53, 0.394);
  padding: 55px 0px;
  border-radius: 50px;
  border: 1px solid gray;
  transition: all 0.2s ease-in-out;
}

.witter-row:hover {
  background-color: rgba(104, 104, 104, 0.466);
  border: 1px solid rgb(202, 202, 202);
}

.projectHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Optional spacing between the logo and title */
}

.witterLogo {
  width: 7%;  /* Adjust as needed */
  height: auto;
  vertical-align: middle;
}

.projectTitle {
  font-size: 10vh;
  margin: 0; /* Remove any default margin */
}

.witter-desc {
  font-size: 20px;
  margin-bottom: 50px;
}

  /* IMAGE */
.witterDesktop {
  max-width: 35%;
}

/* ============================================ */
/* CONTACT ROW */
/* ============================================ */

.contactRowText {
  max-width: 100%;
  color: rgb(201, 201, 201);
  padding-bottom: 30px;
  font-size: calc(1vmax + 6px);
}

.contactCol {
  justify-content: center;
  text-align: center;
}

.contactMeBtn, .witter-btn {
  color: #b0ecff !important; /* Set your desired link color */
  border: 1px solid #b0ecff; /* Set your desired border color */
  border-radius: 5px;
  background-color: #6298d12c;
  padding: 10px 30px;
  transition: all 0.2s ease-in-out;
  margin: 0px 20px;
  text-decoration: none;
  font-size: 25px;
}

.contactMeBtn:hover, .witter-btn:hover {
  background-color: #6298d173;
  color: #9ecdff !important;
  transform: scale(1.02);
  text-decoration: none !important;
}

/* ============================================ */
/* CREDITS ROW */
/* ============================================ */


.creditsRow {
  max-width: 100%;
  justify-content: center;
  text-align: center;
}

.githubLink {
  color: rgba(255, 255, 255, 0.521) !important;
  text-decoration: none !important;
  transition: all 0.3s ease-in-out;
}

.githubLink:hover {
  color: rgba(255, 255, 255, 0.781) !important;
}

/* ============================================ */
/* MEDIA QUERIES */
/* ============================================ */

/* Below we are resizing the info panels and FrontPageRow to fit the screen size when it's below 768px */
@media (max-width: 767px) {
    .info-panel-left, .info-panel-right {
        display: none; /* hide info-panel-left below 768px */
    }

    .FrontPageRow, .aboutMeRow, .projectsRow, .contactRow {
        margin: 70px 20px !important; /* Gives our shortcuts room to breathe at the bottom left and right */
        text-align: center;
        justify-content: center;
    }

    .name-title {
        padding-bottom: 20px;
    }

    .mini-bio {
        max-width: 100%;
    }

    .witterDesktop {
      max-width: 200px;
      width: 200px;
      height: auto;
    }

    .witterLogo {
      width: 60px !important;
    }

    .projectTitle {
      font-size: 60px;
    }

    .witter-desc {
      font-size: 15px !important;
    }

    .witter-btn {
      font-size: 18px;
      width: 200px !important;
      padding: 10px !important;
      margin-bottom: 10px;
    }
}
  

  
  