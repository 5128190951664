.myResumeBtn {
    text-decoration: none;
    font-size: calc(1vmax + 6px);
    color: #b0ecff;
    border: 1px solid #b0ecff;
    padding: 20px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
  }
  
  .myResumeBtn:hover {
    background-color: #6298d173;
    color: #9ecdff !important;
    width: 100px;
    text-decoration: none !important;
  }

  @keyframes blinker {
    0% {
      background-color: transparent;
    }
    25% {
      background-color: #6298d1;
    }
  }
  
  .flashingBackground {
    animation: blinker 3s infinite; /* Flash every 5 seconds */
  }
  