/* ----------------------------------- */
/* LOADING SCREEN */
/* ----------------------------------- */

.loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000e1b;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    transition: all 1s ease-in-out;
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid #b0ecff;
    border-top-color: transparent;
    animation: spin 1s infinite linear;
    margin: 20px !important;
  }
  
  .loading-text {
    margin-top: 10px;
    font-size: 16px;
    color: #b0ecff;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* ----------------------------------- */
  