/* ============================================== */
/* NAVBAR TITLE */
/* ============================================== */

.navbar-logo {
  max-width: 90%;
  border-radius: 100px;
  transition: all 3s ease-in-out;
}

.navbar-logo:hover {
  transform: rotate(1600deg);
  border: 1px solid #497c8b;
}



/* ============================================== */
/* NAVBAR LINKS */
/* ============================================== */

.navbar-links-col {
  justify-content: flex-end; /* Align items to the right */
  align-items: center; /* Center items vertically within the column */
}
.navbar-link a {
  display: inline !important; /* Set to "inline" to keep the link and span side by side */
  text-decoration: none; /* To remove underline if needed */
}

.navbar-link-number {
  display: inline; /* Set to "inline" to keep the number and text side by side */
  color: #b0ecff;
}

.navbar-link {
  text-decoration: none !important; /* Remove underline */
  color: #ebebeb !important; /* Set your desired link color */
  /* Media Query */
  margin: 0px 20px;
  font-size: 20px;
  transition: all 0.2s ease-in-out;
}

.navbar-link:hover {
  text-decoration: none !important; /* Remove underline */
  color: #6298d1 !important; /* Set your desired link color on hover */
}

.nav-icon {
  font-size: 30px;
  color: #b0ecff !important; /* Set your desired link color */
  margin: 0px 10px;
  transition: all 0.2s ease-in-out;
}

.nav-icon:hover {
  color: #9ecdff !important;
  transform: scale(1.2)
}

.resume-link {
  color: #b0ecff !important; /* Set your desired link color */
  border: 1px solid #b0ecff; /* Set your desired border color */
  border-radius: 5px;
  text-decoration: none;
  /* Media Query */
  padding: 10px;
  font-size: 20px;
  margin-left: 15px !important;
  transition: all 0.2s ease-in-out;
}

.resume-link:hover {
  text-decoration: none !important; /* Remove underline */
  background-color: #6298d173;
  color: #9ecdff !important;
}

/* ============================================== */
/* NAVBAR TOGGLE BUTTON ON MOBILE */
/* ============================================== */

/* Turning the Navbar Expand button border white */
.navbar-toggler {
  border-color: #ebebeb !important;
}

.navbar-toggler-icon {
  filter: invert(100%);
  -webkit-filter: invert(100%);
}

/* ============================================== */
/* MEDIA QUERIES */
/* ============================================== */

@media (max-width: 767px) {
  .FrontPageRow {
    margin: 30px 20px !important;
  }
  .navbar-links-col, .navbar-collapse {
    justify-content: center !important; /* Align items to the right */
    align-items: center !important;
  }

  .navbar-link {
    margin: 0px 12px;
    font-size: 14px;
  }

  .resume-link {
    padding: 5px;
    font-size: 14px;
  }
}